







































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'ChemOleMissLab8_Task1',
  components: {
    NumberValue,
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        massMalonicAcid: null,
        massMnSO4: null,
      },
    };
  },
});
